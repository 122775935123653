import Pens from "../ui/pens";

const Index = function() {

    let reviewsOthers = [],
        reviewsMax = null;


    this.__construct = _=>{
        //this.prepareReviews();
        //this.drawPens();
        //window.addEventListener("resize", this.fixReviews);
    }


    this.fixReviews = _=>{
        let needleHeight = reviewsOthers.reduce( (s,e)=>(s+e.clientHeight) ,0) + 30;

        if (reviewsMax.clientHeight < needleHeight) {
            reviewsMax.style.height = `${needleHeight}px`;
        }
    }

    this.prepareReviews = _=>{

        let items = [...document.querySelectorAll(".landing-review")];
        let maxValue = 0;

        items.forEach(item=>{

            if (item.clientHeight > maxValue) {
                if (reviewsMax!==null) {
                    reviewsOthers.push(reviewsMax);
                }
                reviewsMax = item;
                maxValue = item.clientHeight;
                return true;
            }
            reviewsOthers.push(item);
        })
        this.fixReviews();
    }


    this.__construct.apply(this,arguments);
}


export default Index;