const Pen = function() {

}


const PensDrawer = function() {

    let element = null;

    this.__construct = e=>{
        element = e;
        this.drawPen("small");

    }


    this.drawPen = (type, rotate = null) => {
        rotate = rotate===null? (Math.random()*360>>0) : (rotate|0);



        let pen = document.createElement("div");
        pen.setAttribute("class",`pen -${type}`);

        pen.style.transform = `rotate(${rotate}deg)`;

        element.appendChild(pen);



        return pen;
    }


    this.__construct.apply(this,arguments);
}

export default PensDrawer;