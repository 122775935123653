const Turner = function() {
    let SCROLL_DELTA = 300;
    let MOOVING = false;

    this.__construct = _=>{
        let elements = $(".turner");

        if (elements.length == 0) {
            return true;
        }
        $(".turner.-right").on("click", this.moveRight);
        $(".turner.-left").on("click", this.moveLeft);

        SCROLL_DELTA = $(window).width()/3;

        $(function () {
            $(".turner__wrapper").each((idx, element)=>{
                setTimeout(_=>{
                    element.scrollLeft = 0;
                    this.controlVisibility(element);
                },1000);

                $(element).on("scroll", _=>{
                    if (!MOOVING) {
                        this.controlVisibility(element);
                    }
                })
            })
        }.bind(this))
    }

    this.getWrapper = turner=>{
        let wrapper = $(turner.parentNode).find(".turner__wrapper");

        if (wrapper.lenth == 0) {
            return null;
        }
        return wrapper.get(0);
    }

    this.moveRight = (ev)=>{
        let el = ev.target;
        let wrapper = this.getWrapper(el);

        if (!wrapper) {
            return true;
        }
        if (wrapper.scrollLeftMax > wrapper.scrollLeft) {
            MOOVING = true;
            $(wrapper).stop().animate({scrollLeft: wrapper.scrollLeft + SCROLL_DELTA}, 200, _=>{
                MOOVING = false;
                this.controlVisibility(wrapper);
            });

        }
        //this.controlVisibility(wrapper);
    }


    this.moveLeft = (ev)=>{
        let el = ev.target;
        let wrapper = this.getWrapper(el);

        if (!wrapper) {
            return true;
        }

        if (wrapper.scrollLeft > 0) {
            MOOVING = true;
            $(wrapper).stop().animate({scrollLeft: wrapper.scrollLeft - SCROLL_DELTA}, 200, _=>{
                MOOVING = false;
                this.controlVisibility(wrapper);
            });
            //this.controlVisibility(wrapper);
        }
    }

    this.controlVisibility = wrapper=>{
        let parent = $(wrapper.parentNode);

        let left = $(parent).find(".turner.-left"),
            right = $(parent).find(".turner.-right"),
            scroll = wrapper.scrollLeft,
            maxScroll = wrapper.scrollLeftMax;

        left.toggle(scroll > 0);
        right.toggle(maxScroll > scroll);
    }



    this.__construct.apply(this);
};

export default Turner;