import Index from "./pages/index";
import Turner from "./ui/turner.js";
//import Photo from "./ui/photoviewer";

const Initialize = function(){

    this.page = null;

    this.light = null;

    this.init = _=>{
        this.page = new Index();
        new Turner();

        /*this.light = new Photo.PhotoLight();
        var itemsList = $(".photolister__container .photolister__image");
        if (itemsList.length > 0) {
            this.light.imageSet(itemsList);

            $(".photolister__image").click((ev)=>{
                ev.preventDefault();
                var src = $(ev.target).closest(".photolister__image");
                this.light.changeSource(src.get(0));
                this.light.view();
            });
        }*/
    }

    this.init();
}

let initer = new Initialize();






